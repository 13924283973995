import React from 'react';
import { Link, Navigate } from "react-router-dom";
import Api from "../servicios/api";

class Listar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            datosCargados: false,
            cmoviles: [],
            busqueda: "",
            filtroFecha: "",
            filtroGuiaRemision: "",
            filtroGuiaHiraoka: "",
            filtroPlaca: "",
            placasRegistradas: [], // Nuevo estado para almacenar las placas únicas
            filtroEstado: "",
            filtroDescripcion: "",
            filtrosAvanzados: false,
            loggedOut: false,
            currentPage: 1,
            registrosPorPagina: 8
        };
    }

    borrarRegistros = (Idborrar) => {
        const confirmacion = window.confirm("¿Estás seguro de que deseas borrar este registro?");
        
        if (confirmacion) {
            fetch(Api + "?borrar=" + Idborrar)
            .then(respuesta => respuesta.json())
            .then(datosRespuesta => {
                this.cargarDatos();
            })
            .catch(console.log);
        }
    }

    cargarDatos() {
        fetch(Api)
            .then(respuesta => respuesta.json())
            .then(datosRespuesta => {
                const registrosOrdenados = datosRespuesta.sort((a, b) => new Date(b.Fecha) - new Date(a.Fecha));
                
                // Extraer placas únicas de los registros
                const placasUnicas = [...new Set(registrosOrdenados.map(movil => movil.Placa))];

                this.setState({ 
                    datosCargados: true, 
                    cmoviles: registrosOrdenados,
                    placasRegistradas: placasUnicas // Guardar las placas únicas en el estado
                });
            })
            .catch(console.log);
    }

    componentDidMount() {
        this.cargarDatos();
    }

    actualizarFiltro = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    toggleFiltrosAvanzados = () => {
        this.setState({ filtrosAvanzados: !this.state.filtrosAvanzados });
    }

    cerrarSesion = () => {
        const confirmacion = window.confirm("¿Estás seguro de que deseas cerrar sesión?");
        
        if (confirmacion) {
            localStorage.removeItem('role');
            localStorage.removeItem('loggedIn');
            this.setState({ loggedOut: true });
        }
    }

    cambiarPagina = (numeroPagina) => {
        this.setState({ currentPage: numeroPagina });
    }

    paginaAnterior = () => {
        this.setState((prevState) => ({
            currentPage: prevState.currentPage > 1 ? prevState.currentPage - 1 : 1
        }));
    }

    paginaSiguiente = () => {
        const { currentPage } = this.state;
        const totalPaginas = Math.ceil(this.state.cmoviles.length / this.state.registrosPorPagina);
        this.setState({
            currentPage: currentPage < totalPaginas ? currentPage + 1 : totalPaginas
        });
    }

    render() { 
        const { datosCargados, cmoviles, busqueda, filtroFecha, filtroGuiaRemision, filtroGuiaHiraoka, filtroPlaca, placasRegistradas, filtroEstado, filtroDescripcion, filtrosAvanzados, loggedOut, currentPage, registrosPorPagina } = this.state;
        const role = localStorage.getItem('role');
        const placas = localStorage.getItem('placas');

        if (loggedOut) {
            return <Navigate to="/login" />;
        }

        const movilesFiltrados = cmoviles.filter(movil => {
            const fechaMovil = movil.Fecha.split(' ')[0]; // Asumiendo que el formato es 'YYYY-MM-DD HH:mm:ss'
            
            const cumpleFiltros = (busqueda === "" || 
                fechaMovil.includes(busqueda) || 
                movil.GuiaRemisionElectronica.toLowerCase().includes(busqueda.toLowerCase()) || 
                movil.GuiaHiraoka.toLowerCase().includes(busqueda.toLowerCase()) || 
                movil.Placa.toLowerCase().includes(busqueda.toLowerCase()) || 
                movil.Estado.toString().includes(busqueda) || 
                movil.Descripcion.toLowerCase().includes(busqueda.toLowerCase())
            ) && 
            (!filtrosAvanzados || (
                (filtroFecha === "" || fechaMovil === filtroFecha) &&
                (filtroGuiaRemision === "" || movil.GuiaRemisionElectronica.toLowerCase().includes(filtroGuiaRemision.toLowerCase())) &&
                (filtroGuiaHiraoka === "" || movil.GuiaHiraoka.toLowerCase().includes(filtroGuiaHiraoka.toLowerCase())) &&
                (filtroPlaca === "" || movil.Placa.toLowerCase() === filtroPlaca.toLowerCase()) &&
                (filtroEstado === "" || movil.Estado === filtroEstado) &&
                (filtroDescripcion === "" || movil.Descripcion.toLowerCase().includes(filtroDescripcion.toLowerCase()))
            ));

            // Si el rol es '3', filtrar para mostrar solo los registros que coincidan con la placa almacenada
            if (role === '3') {
                return cumpleFiltros && movil.Placa.toLowerCase() === placas.toLowerCase();
            }

            return cumpleFiltros;
        });

        const indexOfLastRegistro = currentPage * registrosPorPagina;
        const indexOfFirstRegistro = indexOfLastRegistro - registrosPorPagina;
        const registrosActuales = movilesFiltrados.slice(indexOfFirstRegistro, indexOfLastRegistro);
        const numeroPaginas = Math.ceil(movilesFiltrados.length / registrosPorPagina);

        if (!datosCargados) {
            return (<div>Cargando...</div>);
        } else {
            return (  
                <div className="card">
                    <div className="card-header">
                        <h4>
                            Lista de Moviles
                            {(role === '1' || role === '3') && (
                                <Link className="btn btn-primary" to={"/crear"} style={{ float: 'right' }}>Agregar Nueva Guia</Link>
                            )}
                            <button className="btn btn-secondary" onClick={this.cerrarSesion} style={{ float: 'right', marginRight: '10px' }}>
                                Cerrar sesión
                            </button>
                        </h4>
                    </div>
                    
                    <div className="card-body">
                        {/* Búsqueda general */}
                        <div className="row mb-1">
                            <div className="col-md-8">
                                <input 
                                    type="text" 
                                    placeholder="Buscar por cualquier campo..." 
                                    className="form-control" 
                                    name="busqueda"
                                    value={busqueda}
                                    onChange={this.actualizarFiltro}
                                />
                            </div>
                            <div className="col-md-4">
                                <button className="btn btn-outline-dark" style={{ float: 'right' }} onClick={this.toggleFiltrosAvanzados}>
                                    {filtrosAvanzados ? "Ocultar filtros avanzados" : "Mostrar filtros avanzados"}
                                </button>
                            </div>
                        </div>
                        {/* Mostrar filtros avanzados solo si están habilitados */}
                        {filtrosAvanzados && (
                            <>
                                <div className="row mb-3">
                                    <div className="col">
                                        <input 
                                            type="date" 
                                            className="form-control" 
                                            name="filtroFecha"
                                            value={filtroFecha}
                                            onChange={this.actualizarFiltro}
                                        />
                                    </div>
                                    {role === '1' && (
                                        <div className="col">
                                            <input 
                                                type="text" 
                                                placeholder="Buscar por N° Guia Remision" 
                                                className="form-control"
                                                name="filtroGuiaRemision"
                                                value={filtroGuiaRemision}
                                                onChange={this.actualizarFiltro}
                                            />
                                        </div>
                                    )}
                                    <div className="col">
                                        <input 
                                            type="text" 
                                            placeholder="Buscar por N° Guia Hiraoka" 
                                            className="form-control"
                                            name="filtroGuiaHiraoka"
                                            value={filtroGuiaHiraoka}
                                            onChange={this.actualizarFiltro}
                                        />
                                    </div>
                                    <div className="col">
                                        <input 
                                            type="text" 
                                            placeholder="Buscar por Descripción" 
                                            className="form-control"
                                            name="filtroDescripcion"
                                            value={filtroDescripcion}
                                            onChange={this.actualizarFiltro}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    {(role === '1' || role === '2') && (
                                        <div className="col">
                                            <select 
                                                className="form-control text-center" 
                                                name="filtroPlaca"
                                                value={filtroPlaca}
                                                onChange={this.actualizarFiltro}
                                            >
                                                <option value=""> -- Seleccionar Placa --</option>
                                                {placasRegistradas.map((placa, index) => (
                                                    <option key={index} value={placa}>{placa}</option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                    <div className="col">
                                        <select 
                                            className="form-control text-center" 
                                            name="filtroEstado"
                                            value={filtroEstado}
                                            onChange={this.actualizarFiltro}
                                        >
                                            <option value=""> -- Seleccionar Estado --</option>
                                            <option value="0">Sin definir</option>
                                            <option value="1">No entregado</option>
                                            <option value="2">Entregado</option>
                                        </select>
                                    </div>
                                </div>
                            </>
                        )}


                        {/* Tabla de resultados filtrados */}
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Fecha y Hora</th>
                                    {role === '1' && <th>N° GUIA Remision</th>}
                                    <th>N° GUIA Hiraoka</th>
                                    <th>Placa del Vehiculo</th>
                                    <th>Fotos</th>
                                    <th>Estado</th>
                                    <th>Descripcion</th>
                                    {(role === '1' || role === '3') && <th>Acciones</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {registrosActuales.map((movil) => (
                                    <tr key={movil.ID}>
                                        <td>{movil.Fecha}</td>
                                        {role === '1' && <td>{movil.GuiaRemisionElectronica}</td>}
                                        <td>{movil.GuiaHiraoka}</td>
                                        <td>{movil.Placa}</td>
                                        <td>
                                            {movil.Foto ? (
                                                <img src={movil.Foto} alt="Foto del móvil" style={{ width: "150px" }} />
                                            ) : (
                                                "No hay foto"
                                            )}
                                        </td>
                                        <td>
                                            {movil.Estado === "2" ? (
                                                <button type="button" className="btn btn-success disabled">Entregado</button>
                                            ) : movil.Estado === "1" ? (
                                                <button type="button" className="btn btn-dark disabled">No entregado</button>
                                            ) : (
                                                <button type="button" className="btn btn-primary disabled">Sin definir</button>
                                            )}
                                        </td>
                                        <td>{movil.Descripcion}</td>
                                        {(role === '1' || role === '3') && (
                                            <td>
                                                <div className="btn-group" role="group" aria-label="Acciones">
                                                    <Link className="btn btn-warning" to={"/editar/" + movil.ID}>Editar</Link>
                                                    <button type="button" className="btn btn-danger" onClick={() => this.borrarRegistros(movil.ID)}>Borrar</button>
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {/* Paginación */}
                        <nav>
                            <ul className="pagination">
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={this.paginaAnterior}>
                                        Previous
                                    </button>
                                </li>
                                {Array.from({ length: numeroPaginas }, (_, i) => (
                                    <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                        <button className="page-link" onClick={() => this.cambiarPagina(i + 1)}>
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                                <li className={`page-item ${currentPage === numeroPaginas ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={this.paginaSiguiente}>
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            );
        }
    }
}

export default Listar;
