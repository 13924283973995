import React from 'react';
import { Link, Navigate } from "react-router-dom";
import Api from "../servicios/api";

class Crear extends React.Component {
    constructor(props) {
        super(props);

        // Verificar si el usuario es administrador (rol 1 o 3)
        const role = localStorage.getItem("role");
        const placas = localStorage.getItem("placas");

        if (role !== "1" && role !== "3") {
            window.location.href = "/login"; // Redirige al login si no es administrador
        }

        // Función para obtener la fecha y hora actual ajustada a la zona horaria de Perú (UTC-5)
        const obtenerFechaPeru = () => {
            const now = new Date();
            const utcOffset = -5; // Perú está en UTC-5
            const fechaPeru = new Date(now.getTime() + utcOffset * 60 * 60 * 1000)
                .toISOString()
                .substring(0, 16); // Formato 'YYYY-MM-DDTHH:mm'
            return fechaPeru;
        };

        this.state = {  
            fecha: obtenerFechaPeru(),
            guiaremi: "",
            guiah: "",
            placa: role === "3" ? placas || "" : "",  // Solo asigna la placa si el rol es 3
            foto: "",  // Aquí se almacenará la imagen en formato Base64
            estado: "0",  // Valor por defecto "Sin definir"
            descripcion: "",
            redirect: false 
        };
    }

    enviarDatos = (e) => {
        e.preventDefault();
    
        // Obtener la fecha y hora ajustada a la zona horaria de Perú antes de enviar los datos
        const now = new Date();
        const utcOffset = -5; // Perú está en UTC-5
        const fechaPeru = new Date(now.getTime() + utcOffset * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 19)
            .replace('T', ' '); // Devuelve 'YYYY-MM-DD HH:mm:ss'
    
        const { guiaremi, guiah, placa, foto, estado, descripcion } = this.state;
        const datosEnviar = {
            fecha: fechaPeru,
            guiaremi: guiaremi, 
            guiah: guiah, 
            placa: placa, 
            foto: foto,  // Aquí va la imagen en Base64
            estado: estado, 
            descripcion: descripcion
        };
    
        fetch(Api+"?insertar=1", {
            method: "POST",
            body: JSON.stringify(datosEnviar),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(respuesta => respuesta.json())
        .then(datosRespuesta => {
            this.setState({ redirect: true });
        })
        .catch(console.log);
    }

    // Método para capturar el valor de los campos
    cambioValor = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    // Método para convertir la imagen a Base64
    cambioValorImagen = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        
        reader.onloadend = () => {
            this.setState({ foto: reader.result });  // Aquí se guarda la imagen en Base64
        };
        
        if (file) {
            reader.readAsDataURL(file);  // Lee el archivo como una URL de datos en formato Base64
        }
    }

    render() { 
        const { fecha, guiaremi, guiah, placa, foto, estado, descripcion, redirect } = this.state;
        const role = localStorage.getItem("role");

        if (redirect) {
            return <Navigate to="/" />;
        }

        return (  
            <div className="card">
                <h4 className="card-header">Agregar Moviles</h4>
                <div className="card-body">
                    <form onSubmit={this.enviarDatos}>
                        <div className="form-group">
                            <label>Fecha y Hora:</label>
                            <input 
                                type="datetime-local" 
                                name="fecha" 
                                value={fecha} 
                                className="form-control"
                                readOnly // Esto hace que el usuario no pueda modificar la fecha y hora
                            />
                        </div>
                        {role === '1' && (
                        <div className="form-group">
                            <label>N° Guia de Remision Electronica:</label>
                            <input type="text" name="guiaremi" onChange={this.cambioValor} value={guiaremi} className="form-control" />
                        </div>
                        )}
                        <div className="form-group">
                            <label>N° Guia Hiraoka:</label>
                            <input type="text" name="guiah" onChange={this.cambioValor} value={guiah} className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>Placa:</label>
                            <input type="text" name="placa" onChange={this.cambioValor} value={placa} className="form-control" readOnly={role === "3"}/>
                        </div>
                        <div className="form-group">
                            <label>Foto:</label>
                            <input type="file" name="foto" accept="image/*" onChange={this.cambioValorImagen} className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>Estado:</label>
                            <select name="estado" onChange={this.cambioValor} value={estado} className="form-control">
                                <option value="0">Sin definir</option>
                                <option value="1">No entregado</option>
                                <option value="2">Entregado</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Descripcion:</label>
                            <input type="text" name="descripcion" onChange={this.cambioValor} value={descripcion} className="form-control" />
                        </div>
                        <div className="btn-group" role="group">
                            <button type="submit" className="btn btn-primary">Agregar Registro</button>
                            <Link to="/" className="btn btn-secondary">Cancelar</Link>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Crear;
