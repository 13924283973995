import React from "react";
import { useParams } from "react-router-dom";
import EditarComponente from "./EditarComponente"; // Importa tu componente de clase

function Editar() {
  const { ID } = useParams(); // Obtenemos el ID de la URL
  return <EditarComponente id={ID} />; // Pasamos el ID como prop
}

export default Editar;

