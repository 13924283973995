/*const api="http://localhost/Moviles/";
export default api;*/

/*const api="https://raegsactracking.com/Moviles/index.php"; [ [ [ [C O N S I D E R A R  E S T O] ] ] ]]]]]]]
export default api;*/

/*const api="http://localhost/Moviles/";
export default api;*/

const api="https://raegsactracking.com/Moviles/index.php";
export default api;