import './App.css';
import { useState, useEffect } from "react"; // Usamos estado y efecto
import Listar from "./componentes/Listar";
import Crear from "./componentes/Crear";
import Editar from "./componentes/Editar";
import Login from "./componentes/Login"; // Agregamos el componente Login
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

function App() {
  const [role, setRole] = useState(localStorage.getItem("role")); // Estado del rol del usuario
  const [placas, setPlacas] = useState(localStorage.getItem("placas")); // Devuelve el nombre de la placa


  // Monitorear cambios en el role almacenado en localStorage
  useEffect(() => {
    const storedRole = localStorage.getItem("role");
    if (storedRole) {
      setRole(storedRole); // Actualizar el estado del rol si cambia en localStorage
    }
  }, [role]); // Solo se actualiza si cambia el rol

  return (
    <div className="container">
      <Router>
        <Routes>
          {/* Ruta para el login */}
          <Route path="/login" element={<Login onLoginSuccess={(newRole) => setRole(newRole)} />} />

          {/* Si el usuario tiene rol, mostrar las rutas protegidas */}
          {role ? (
            <>
              <Route path="/" element={<Listar />} />

              {/* Solo los administradores pueden crear y editar */}
              {(role === "1" || role === "3")&& (
                <>
                  <Route path="/crear" element={<Crear />} />
                  <Route path="/editar/:ID" element={<Editar />} />
                </>
              )}
            </>
          ) : (
            // Si no hay rol, redirigir al login
            <Route path="*" element={<Navigate to="/login" />} />
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
