import React, { useState, useEffect } from 'react';
import { Link, Navigate } from "react-router-dom";
import Api from "../servicios/api";

const Login = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false); // Indicador de carga
    const [error, setError] = useState(null); // Manejo de errores
    const [role, setRole] = useState(localStorage.getItem('role') || null); // Obtener el rol si está en localStorage
    const [placas, setPlacas] = useState(localStorage.getItem('placas') || null); // Obtener el nombre (placa) si está en localStorage

    // Verificar si ya está logueado al cargar la página
    useEffect(() => {
        if (localStorage.getItem('loggedIn') === 'true') {
            setLoggedIn(true);
        }
    }, []);

    const validateForm = () => {
        // Validación básica del formulario
        if (username === '' || password === '') {
            setError('Todos los campos son obligatorios');
            return false;
        }
        return true;
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        
        // Limpiar errores anteriores
        setError(null);

        // Validar formulario
        if (!validateForm()) return;

        // Indicador de carga mientras se realiza la petición
        setLoading(true);

        try {
            // Enviar los datos de login al backend
            const response = await fetch(Api + "?login=1", {
                method: 'POST',
                body: JSON.stringify({ username, password }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();

            if (data.success) {
                setLoggedIn(true);
                setRole(data.role);
                setPlacas(data.placas);
                localStorage.setItem('role', data.role); // Guarda el rol en localStorage
                localStorage.setItem('placas', data.placas); // Guarda la placa en localStorage
                localStorage.setItem('loggedIn', 'true'); // Indica que el usuario está logueado
                if (props.onLoginSuccess) {
                    props.onLoginSuccess(data.role); // Llama a la función onLoginSuccess cuando se loguea correctamente
                }
            } else {
                setError('Usuario o contraseña incorrectos'); // Mensaje de error si las credenciales fallan
            }
        } catch (error) {
            setError('Hubo un error al procesar la solicitud. Intenta nuevamente.'); // Mensaje de error en caso de fallos de red
        } finally {
            setLoading(false); // Detener el indicador de carga
        }
    };

    // Si el usuario ya inició sesión, redirigir al home
    if (loggedIn) {
        return <Navigate to="/" />;
    }

    // Estilos inline para centrar el formulario
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // 100% de la altura de la pantalla
        backgroundColor: '#f4f4f4'
    };

    const formStyle = {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
        width: '300px',
        textAlign: 'center'
    };

    const inputStyle = {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: '1px solid #ccc'
    };

    const buttonStyle = {
        padding: '10px 15px',
        width: '100%',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    };

    return (
        <div style={containerStyle}>
            <form onSubmit={handleLogin} style={formStyle}>
                <h2>Iniciar Sesión</h2>
                
                {error && <div style={{ color: 'red' }}>{error}</div>} {/* Mostrar mensaje de error si existe */}
                
                <div className="form-group">
                    <input 
                        type="text" 
                        placeholder="Usuario" 
                        value={username} 
                        onChange={(e) => setUsername(e.target.value)} 
                        required 
                        style={inputStyle}
                    />
                </div>
                
                <div className="form-group">
                    <input 
                        type="password" 
                        placeholder="Contraseña" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        required 
                        style={inputStyle}
                    />
                </div>

                <button type="submit" style={buttonStyle} disabled={loading}>
                    {loading ? 'Cargando...' : 'Ingresar'}
                </button>
            </form>
        </div>
    );
};

export default Login;
