import React from "react";
import { Link, Navigate } from "react-router-dom";
import Api from "../servicios/api";

class EditarComponente extends React.Component {
    constructor(props) {
        super(props);

        // Verificar si el usuario es administrador
        const role = localStorage.getItem("role");
        if (role !== "1" && role !== "3") {
            window.location.href = "/login"; // Redirige al login si no es administrador
        }

        this.state = {
            datosCargados: false, 
            cmovile: [],
            redirect: false 
        };
    }

    cambioValor = (e) => {
        const state = this.state.cmovile;
        state[e.target.name] = e.target.value;
        this.setState({ cmovile: state });
    }

    cambioValorImagen = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        
        reader.onloadend = () => {
            const state = this.state.cmovile;
            state.Foto = reader.result;  // Aquí se guarda la imagen en Base64
            this.setState({ cmovile: state });
        };
        
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    enviarDatos = (e) => {
        e.preventDefault();
        const { ID, Fecha, GuiaRemisionElectronica, GuiaHiraoka, Placa, Foto, Estado, Descripcion } = this.state.cmovile;

        var datosEnviar = { 
            id: ID, 
            fecha: Fecha, 
            guiaremi: GuiaRemisionElectronica, 
            guiah: GuiaHiraoka, 
            placa: Placa, 
            foto: Foto,  // Aquí se envía la imagen en formato Base64
            estado: Estado, 
            descripcion: Descripcion 
        };

        fetch(Api + "?actualizar=1", {
            method: "POST",
            body: JSON.stringify(datosEnviar),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(respuesta => respuesta.json())
        .then(datosRespuesta => {
            this.setState({ redirect: true }); 
        })
        .catch(console.log);
    }

    componentDidMount() {
        const { id } = this.props;

        fetch(Api + `?consultar=${id}`)
        .then(respuesta => respuesta.json())
        .then(datosRespuesta => {
            this.setState({
                datosCargados: true, 
                cmovile: datosRespuesta[0]
            });
        })
        .catch(console.log);
    }

    render() { 
        const { datosCargados, cmovile, redirect } = this.state;
        const role = localStorage.getItem('role');

        if (redirect) {
            return <Navigate to="/" />;
        }
    
        if (!datosCargados) { 
            return (<div>Cargando...</div>); 
        } else {
            return (
                <div className="card">
                    <h4 className="card-header">Editar Moviles</h4>
                    <div className="card-body">
                        <form onSubmit={this.enviarDatos}>
                            <div className="form-group">
                                <label>Clave: </label>
                                <input 
                                    type="text" 
                                    readOnly 
                                    className="form-control" 
                                    value={cmovile.ID || ""} 
                                    onChange={this.cambioValor} 
                                    name="ID" 
                                />
                            </div>
                            <div className="form-group">
                                <label>Fecha:</label>
                                <input 
                                    type="datetime-local" 
                                    name="Fecha" 
                                    onChange={this.cambioValor} 
                                    value={cmovile.Fecha || ""} 
                                    className="form-control"
                                />
                            </div>
                            {role === '1' && (
                            <div className="form-group">
                                <label>N° Guia de Remision Electronica:</label>
                                <input 
                                    type="text" 
                                    name="GuiaRemisionElectronica" 
                                    onChange={this.cambioValor} 
                                    value={cmovile.GuiaRemisionElectronica || ""} 
                                    className="form-control" 
                                />
                            </div>
                            )}
                            <div className="form-group">
                                <label>N° Guia Hiraoka:</label>
                                <input 
                                    type="text" 
                                    name="GuiaHiraoka" 
                                    onChange={this.cambioValor} 
                                    value={cmovile.GuiaHiraoka || ""} 
                                    className="form-control" 
                                />
                            </div>
                            <div className="form-group">
                                <label>Placa:</label>
                                <input 
                                    type="text" 
                                    name="Placa" 
                                    onChange={this.cambioValor} 
                                    value={cmovile.Placa || ""} 
                                    className="form-control" 
                                />
                            </div>
                            <div className="form-group">
                                <label>Foto:</label>
                                <input 
                                    type="file" 
                                    name="Foto" 
                                    accept="image/*" 
                                    onChange={this.cambioValorImagen} 
                                    className="form-control" 
                                />
                            </div>
                            <div className="form-group">
                                <label>Estado:</label>
                                <select 
                                    name="Estado" 
                                    onChange={this.cambioValor} 
                                    value={cmovile.Estado || "0"} 
                                    className="form-control"
                                >
                                    <option value="0">Sin definir</option>
                                    <option value="1">No entregado</option>
                                    <option value="2">Entregado</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Descripcion:</label>
                                <input 
                                    type="text" 
                                    name="Descripcion" 
                                    onChange={this.cambioValor} 
                                    value={cmovile.Descripcion || ""} 
                                    className="form-control" 
                                />
                            </div>
                            <div className="btn-group" role="group">
                                <button type="submit" className="btn btn-primary">Actualizar Registro</button>
                                <Link to="/" className="btn btn-secondary">Cancelar</Link>
                            </div>
                        </form>
                    </div>
                </div>
            );
        }
    }
}

export default EditarComponente;